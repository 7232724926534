@import '../index.scss';

.page {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    max-width: 64rem;
    margin: auto;

    &__title {
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 1;
        cursor: pointer;
        transition: $t;

        @media screen and (min-width: 700px) {
            font-size: 2rem;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &__nav,
    &__top-bar {
        display: flex;
        align-items: center;
        gap: $p2;
        border-bottom: 1px solid $bg;
        justify-content: flex-end;
    }

    &__top-bar {
        justify-content: space-between;
        box-shadow: 0 $p 0 rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $content-color;
        padding: $p;
        overflow: hidden;
    }

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;
        margin-top: 5rem;
        overflow-x: hidden;

        @media screen and (min-width: 600px) {
            margin-top: 4rem;
        }
    }

    &__queries {
        display: flex;
        align-items: center;
        gap: $p;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: visible;
    }
}
