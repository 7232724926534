@import '../index.scss';



.popup-menu {
    position: fixed;
    top: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    box-shadow: 0 0 $p 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    border-radius: $p;
    @include popup;

    &__item {
        @include size-mins;
        background-color: $primary;
        color: $on-primary;
        text-decoration: none;
        padding: 0 $p;
        margin: 0;
        border: 1px solid $primary;
        display: inline-block;
        height: 2rem;
        min-width: 2rem;
        font-size: 0.8rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;
        text-transform: uppercase;
        font-size: 0.8rem;
        text-align: left;
        justify-content: flex-start;
        border-bottom: 1px solid $bg;
        gap: $p;

        * {
            pointer-events: none;
        }

        &:first-child {
            border-top-left-radius: 0.4rem;
            border-top-right-radius: 0.4rem;
        }

        &:hover {
            background-color: $primary-hover;
            border-color: $primary-hover;
            border-bottom-color: $bg;
        }

        &--danger {
            background-color: red;
            color: $content-color;
        }

        &--active {
            background-color: $active;
            border-color: $active;
            border-bottom-color: $bg;

            &:hover {
                background-color: $active-hover;
                border-color: $active-hover;
                border-bottom-color: $bg;
            }
        }

        @media (min-width: 32rem) {
            font-size: 0.6rem;
            min-height: 1.6rem;
            min-width: 1.6rem;
        }

        &:last-child {
            border-bottom-left-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
            border: 0;
        }
    }
}

