@import '../index.scss';

.query-builder {
    padding: $p;

    &__header {
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        color: darkgrey;
    }

    &__line {
        display: flex;
        gap: $p2;
    }

    &__producer-select {
        display: flex;
        align-items: stretch;

        .query__item {
            display: flex;
        }

        textarea {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: none;
        }
    }
}
