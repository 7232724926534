
.q-product {
    &__modal {
        width: calc(100vw - 1rem);
        max-width: 50rem;
        min-height: 50vh;
        max-width:  calc(100vw - 1rem);
        overflow: auto;
    }
}
