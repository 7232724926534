@import './index.scss';

body {
    background-color: $bg;
    overflow-x: hidden;
    overflow-y: auto;
}

.app {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: 0.9rem;
    overflow: hidden;

    @media screen and (min-width: 1000px) {
        font-size: 1rem;
    }
}

.hidden {
    display: none;
}

.icon-button,
a,
button {
    @include clickable;
}

a {
    text-decoration: none;

    &--fresh {
        color: $primary-text !important;

        &:-webkit-any-link {
            color: $primary-text !important;

        }

        &:visited {
            color: $primary-text !important;
        }
    }
}

.icon-button {
    border-radius: 9999px;
}

.sicon-button {
    width: 2rem;
    height: 2rem;
    padding: $p2;
    box-sizing: border-box;

    cursor: pointer;
    transition: $t;
    &:hover {
        background-color: $active;
    }
}

.file-droppable {
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: ($p / 2) dashed $active;
        opacity: 1;
        content: ' ';
        pointer-events: none;
        @include fancy;
    }

    &--shift {
        &:after {
            content: 'SHIFT';
            display: flex;
            align-items: center;
            justify-content: center;
            color: $active;
            font-weight: 700;
            font-size: 2rem;
        }
    }
}

.sub {
    opacity: 0.5;
}

h1,
h2,
h3,
h4,
h5,
pre,
p {
    padding: 0;
    margin: 0;
}

pre {
    white-space: break-spaces;
    word-break: break-all;
}


@media screen and (max-width: 1000px) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
        font-size: 16px !important;
    }
}

.no-bounce {
    overscroll-behavior: none;
    overflow: hidden;
}

.yo {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    color: $content-color;
    display: inline-block;
    padding: $p;
}

.p {
    padding: $p;
}

.tp {
    padding-top: $p;
    display: block;
}

.bp {
    padding-bottom: $p;
}

.pv {
    padding-top: $p;
    padding-bottom: $p;
}
.flex1 {
    flex-grow: 1;
    flex-shrink: 1;
}

body.with-modal {
    overflow: hidden;
}

.a {
    color: $primary-text;
    cursor: pointer;
    &:hover {
        color: $primary-hover;
    }
}

.grower {
    flex-grow: 1000;
}

.spacer {
    display: flex;
    align-items: center;
    gap: $p2;

    &--p {
        gap: $p
    }

    &--end {
        justify-content: flex-end;
    }

    &--between {
        justify-content: space-between;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--vertical {
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
    }
}

.s {
    font-size: 0.8rem;
}

.empty {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
    color: silver;
    flex-grow: 1;
}

.card {
    background-color: $content-color;
    border-radius: $p;
}

.padded {
    padding: $p;
}

.to-right {
    display: flex;
    justify-content: flex-end;
}

.w100 {
    width: 100%;
    box-sizing: border-box;
}

.margined {
    margin: $p;
}

.text-input-with-value {
    border: 1px solid $primary;

    &::placeholder {
        color: $on-input-color;
    }
}

input,
textarea {
    background-color: var(--input-color);
    color: var(--on-input-color);
    outline: none;
    @include size-mins;
}

.foldable {
    max-height: 0;
    overflow: hidden;
    transition: $t;

    &--open {
        max-height: 100vh;
    }
}

textarea {
    overflow: hidden;
}

.p404 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    font-size: 2rem;
}

.portal-open {
    pointer-events: none;
}

.portal {
    pointer-events: all;
}