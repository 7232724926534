.close-modal-button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 9999px;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
}
